import { Link } from 'gatsby';
import React from 'react';
import Layout from '../../components/layout/layout';

const ZmianaKoloruAuta: React.FC = () => {
    return (
        <Layout
            title="Zmiana koloru auta - WrapTown - profesjonalne oklejanie
        samochodów"
            desc="Jeśli chcesz żeby Twoje auto wyglądało zjawiskowo, zapraszamy na zmianę koloru auta folią 3M. Niesamowite kolory sprawią, że samochoód będzie bardzo widoczny na ulicy."
            url="https://wraptown.pl/zmiana-koloru-auta"
            type="website"
        >
            <main className="px-4 max-w-7xl mx-auto pt-20 pb-20 lg:px-6">
                <div className="mb-10">
                    <p>
                        <Link to="/">Home</Link> /{' '}
                        <Link to="/uslugi">Usługi</Link> / Zmiana koloru auta
                    </p>
                </div>
                <div>
                    <h1 className="text-4xl mb-6 font-extrabold text-black xl:text-5xl leading-tight xl:leading-snug">
                        Zmiana koloru auta - WrapTown
                    </h1>
                    <p>
                        Znudził Ci się stary koloru Twojego samochodu?
                        Skorzystaj z naszej usługi zmiany koloru auta, a my
                        sprawimy aby Twoje auto zabłysło na nowo.
                    </p>
                    <p>
                        Folia, którą używamy aby oklejać samochody służy nie
                        tylko do tuningu optycznego, ale także do ochrony
                        oryginalnego lakieru w Twoim samochodzie.
                    </p>
                    <p>
                        <Link to="/kontakt">Skontaktuj się z nami</Link>, aby
                        poznać szczegółową wycenę usługi.
                    </p>
                </div>
            </main>
        </Layout>
    );
};

export default ZmianaKoloruAuta;
